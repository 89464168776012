export const projectsData = [
  {
    id: 1,
    title: "Truck Load and Dispatch",
    // date: "Janvier 2020",
    languages: ["Python", "Django", "SCSS", "JavaScript", "Bootstrap"],
    infos:
      "Check Posted loads by Dispatch or users with account access, read or check for commitment. The App presents an open load board where anyone can see a daily posted load, but the user needs to sign in and create an account to access a Dashboard control to create and edit, delete or repost a load.",
       img: "./assets/img/python.png",
    link: 'https://truckloads.herokuapp.com/',
  },
  {
    id: 2,
    title: "Head Chef Kitchen",
    // date: "Mars 2020",
    languages: ["PHP", "SCSS", "SCSS", "CSS", "JavaScript"],
    infos:
      "Search for a meal with keyword options or by ingredients on a responsive app user-friendly  Cooking application. Need imagination? Press the random button to see global recipes with a youtube link at the bottom, a description of how-to, and measures at the bottom page.",
    img: "./assets/img/head-kitchen.png",
    link: "https://head-chef-kitchen.herokuapp.com/",
  },
  {
    id: 3,
    title: "Blog and Article + Jokes",
    // date: "Avril 2020",
    languages: ["Sequelize", "Express", "Node.js", "CSS", "JavaScript"],
    infos:
      "Personal Online Blog with login authentication access to store articles or daily dreams with random infinite jokes to change the mode like coffee to write more lines are added with an analog clock all for you in 1 app. Happy blogging.",
    img: "./assets/img/article.png",
    link: "https://blog-articles-dreams.herokuapp.com/",
  },
  {
    id: 4,
    title: "Bricks Breacker",
    // date: "Juillet 2020",
    languages: ["Canvas 2D", "JavaScript", "CSS"],
    infos:
      "The game interface uses a paddle to catch a fireball from falling. The manipulation is operated through a paddle located at the bottom of the screen. The mouse provides a faster control for the paddle, and the right and left arrows are also used for direction. Media player with a piece of relaxing music at the bottom. Music: Main Them: 'Emergence'  Copyright © Mortal Kombat ",
    img: "./assets/img/game.png",
    link: "https://dannyjajr.github.io/project-1-DannyGame/",
  },
];
