import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {

  return (
    <div className="navigation">
      <ul>
        <NavLink to="/" exact activeClassName="nav-active" className="hover">
          <li><h3>Home</h3></li>
        </NavLink>

        <li className="nav-portfolio"><h3>Portfolio</h3>
          <ul className="nav-projects">
            <NavLink to="/projet-1" activeClassName="nav-active" className="hover">
              <li>: Truck Load and Dispact</li>
            </NavLink>
            <NavLink to="/projet-2" activeClassName="nav-active" className="hover">
              <li>: Head Chef Kitchen</li>
            </NavLink>
            <NavLink to="/projet-3" activeClassName="nav-active" className="hover">
              <li>: Blog and Article plus Jokes Corner</li>
            </NavLink>
            <NavLink to="/projet-4" activeClassName="nav-active" className="hover">
              <li>: Canvas Game</li>
            </NavLink>
          </ul>
        </li>

        <NavLink to="/contact" activeClassName="nav-active" className="hover">
          <li><h3>Contact</h3></li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Navigation;
