import React from "react";
// import { useState } from "react";

const ContactForm = () => {
  // const [name, setName] = useState("");
  // const [company, setCompany] = useState("");
  // const [phone, setPhone] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let nameS = document.getElementById("name");
  //   let emailS = document.getElementById("email");
  //   let messageS = document.getElementById("message");
  //   let formMess = document.querySelector(".form-message");

  //   const isEmail = () => {
  //     let isMail = document.getElementById("not-mail");
  //     let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  //     if (email.match(regex)) {
  //       isMail.style.display = "none";
  //       return true;
  //     } else {
  //       isMail.style.display = "block";
  //       isMail.style.animation = "dongle 1s";
  //       setTimeout(() => {
  //         isMail.style.animation = "none";
  //       }, 1000);
  //       return false;
  //     }
  //   };

  //   if (name && isEmail() && message) {
  //     const templateId = "template_aofmtvBG";

  //     nameS.classList.remove("red");
  //     emailS.classList.remove("red");
  //     messageS.classList.remove("red");

  //     sendFeedback(templateId, {
  //       name,
  //       company,
  //       phone,
  //       email,
  //       message,
  //     });
  //   } else {
  //     formMess.innerHTML = "Thank you correctement les champs requis *";
  //     formMess.style.background = "rgb(253, 87, 87)";
  //     formMess.style.opacity = "1";

  //     if (!name) {
  //       nameS.classList.add("error");
  //     }
  //     if (!email) {
  //       emailS.classList.add("error");
  //     }
  //     if (!message) {
  //       messageS.classList.add("error");
  //     }
  //   }
  // };

  // const sendFeedback = (templateId, variables) => {
  //   let formMess = document.querySelector(".form-message");

  //   window.emailjs
  //     .send("gmail", templateId, variables)
  //     .then((res) => {
  //       formMess.innerHTML =
  //         "Message send ! I will Text you back soon.";
  //       formMess.style.background = "#00c1ec";
  //       formMess.style.opacity = "1";

  //       document.getElementById("name").classList.remove("error");
  //       document.getElementById("email").classList.remove("error");
  //       document.getElementById("message").classList.remove("error");
  //       setName("");
  //       setCompany("");
  //       setPhone("");
  //       setEmail("");
  //       setMessage("");

  //       setTimeout(() => {
  //         formMess.style.opacity = "0";
  //       }, 5000);
  //     })
  //     .catch(
  //       (err) =>
  //         (formMess.innerHTML =
  //           "Error Please Try again.")
  //     );
  // };

  return (
      
        <div className="contact-form">
          <div className="map-sect">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d93877.8703569287!2d-73.88072059485674!3d42.668063066390125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89de0a3caa20a675%3A0xb8c16874ae7576bc!2sNew%20York%20State%20Museum!5e0!3m2!1sen!2sus!4v1626119596326!5m2!1sen!2sus" width="500"  height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0" title="This is a unique title"/>
                    {/* <iframe src="" width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe> */}
          </div>
          </div>
        






    // <form className="contact-form">
    //   <h2>contactez-nous</h2>
    //   <div className="form-content">
    //     <input
    //       type="text"
    //       id="name"
    //       name="name"
    //       required
    //       onChange={(e) => setName(e.target.value)}
    //       placeholder="nom *"
    //       value={name}
    //     />
    //     <input
    //       type="text"
    //       id="company"
    //       name="company"
    //       onChange={(e) => setCompany(e.target.value)}
    //       placeholder="société"
    //       value={company}
    //     />
    //     <input
    //       type="text"
    //       id="phone"
    //       name="phone"
    //       onChange={(e) => setPhone(e.target.value)}
    //       placeholder="téléphone"
    //       value={phone}
    //     />
    //     <div className="email-content">
    //       <label id="not-mail">Email non valide</label>
    //       <input
    //         type="mail"
    //         id="email"
    //         name="email"
    //         required
    //         onChange={(e) => setEmail(e.target.value)}
    //         placeholder="email *"
    //         value={email}
    //       />
    //     </div>
    //     <textarea
    //       id="message"
    //       name="message"
    //       onChange={(e) => setMessage(e.target.value)}
    //       placeholder="message *"
    //       value={message}
    //       required
    //     />
    //   </div>
    //   <input
    //     className="button hover"
    //     type="submit"
    //     value="envoyer"
    //     onClick={handleSubmit}
    //   />
    //   <div className="form-message"></div>
    // </form>
  );
};

export default ContactForm;
